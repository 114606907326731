import './services/sentry';
import './services/firebase';
import 'dayjs/locale/he';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime)
dayjs.locale('he');

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'lightgallery/css/lightgallery.css';
import {App} from "./app.tsx";

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
)
