import _ from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {createFileRoute, useRouter} from '@tanstack/react-router'
import Typography from '@mui/material/Typography'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import numbro from 'numbro'
import React from 'react'
import {OrderNumber} from '../../../components/order-number.tsx'
import {usePartner} from '../../../services/auth.tsx'
import {ProfilePhoto} from '../../../components/partner-profile.tsx'
import {ClientOrderForPartner} from '@tarimli-mono/api/src/shared/client-model/client-order.ts'
import {SxProps} from '@mui/material/styles'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Accordion from '@mui/material/Accordion'

export const Route = createFileRoute('/_auth/partner_/orders')({
  component: PartnerOrdersScreen,
})

const labels = {
  orderSubmittedAt: 'נשלחה בתאריך',
  newOrders1: 'הזמנות חדשות',
  newOrders2: 'ממתינות להצעות מחיר',

  acceptedOffers1: '',
  acceptedOffers2: 'הובלות סגורות ומאושרות - לביצוע',
  submittedOffers1: 'הצעות שהגשתי',
  submittedOffers2: 'ממתינות לאישור לקוח',

  ordersIRejected1: 'הזמנות שדחיתי',
  ordersIMissed: 'הזמנות שפספסתי',
  ordersIMissed2: 'הזמנות שפספסתי',

  noNewOrders: 'אין הזמנות חדשות',
  onDate: 'בתאריך',
  origin: 'מוצא',
  destination: 'יעד',
  submittedAmount: 'סכום',
  nis: '₪',
  myProfile: 'הפרופיל שלי',
}

function PartnerOrdersScreen() {
  const [expanded, setExpanded] = React.useState<string | false>('section1');
  const partner = usePartner()

  const router = useRouter()

  const onClickOrder = async (order: ClientOrderForPartner) => {
    await router.navigate({
      to: `/partner/order/$orderId`,
      params: {
        /* @ts-expect-error False alarm */
        orderId: String(order.id),
      },
    })
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickSectionHeader =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      window.scrollTo({top: 0, behavior: 'smooth'})
    };


  const section = (
    sectionName: string,
    title1: string,
    title2: string,
    children,
    sx: SxProps = {},
    sx2: SxProps = {},
  ) => {
    return (
      <Accordion expanded={expanded === sectionName} onChange={onClickSectionHeader(sectionName)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Stack
            sx={{
              flexGrow: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              {title1}
            </Typography>
            <Typography
              sx={{
                fontWeight: 900,
                fontSize: 16,
              }}
            >
              {title2}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={2}>
            {children}
          </Stack>
        </AccordionDetails>
      </Accordion>
    )
  }

  const acceptedOffers = (sectionName: string) =>
    section(
      sectionName,
      labels['acceptedOffers1'],
      labels['acceptedOffers2'],
      _.sortBy(partner.acceptedOffersOrders, 'createdAt')
        .reverse()
        .map((order) => {
          return (
            <Stack
              onClick={() => onClickOrder(order)}
              gap={1}
              direction={'row'}
              sx={{
                cursor: 'pointer',
                border: '1px solid #F3EBFF',
                borderRadius: 1,
                clipPath: 'border-box',
              }}
              key={order.id}
            >
              <Box sx={{padding: 1, flexGrow: 1, position: 'relative'}}>
                <OrderNumber
                  orderId={order.id}
                  sx={{position: 'absolute', top: 5, right: 0}}
                />
                <Typography
                  sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                >
                  {labels['onDate']}
                </Typography>
                <Typography sx={{}}>
                  {order?.content.movingDateFormatted}
                </Typography>
                <Typography
                  sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                >
                  {labels['origin']}
                </Typography>
                <Typography sx={{}}>
                  {order.content.strDepartAddress}
                </Typography>
                <Typography
                  sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                >
                  {labels['destination']}
                </Typography>
                <Typography sx={{}}>
                  {order.content.strArriveAddress}
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: 1,
                  backgroundColor: '#F3EBFF',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ChevronLeftIcon sx={{color: '#6F4DFF'}}/>
              </Box>
            </Stack>
          )
        }),
    )

  const inviteOffersSection = (sectionName: string) =>
    section(
      sectionName,
      labels['newOrders1'],
      labels['newOrders2'],
      _.sortBy(partner.ordersForOffer, ['submittedAt', 'createdAt'])
        .reverse()
        .map((order) => {
          return (
            <Stack
              onClick={() => onClickOrder(order)}
              gap={1}
              direction={'row'}
              sx={{
                cursor: 'pointer',
                border: '1px solid #F3EBFF',
                borderRadius: 1,
                clipPath: 'border-box',
              }}
              key={order.id}
            >
              <Stack
                gap={1}
                sx={{padding: 1, flexGrow: 1, position: 'relative'}}
              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: 10,
                      opacity: 0.5,
                    }}
                  >
                    {labels.orderSubmittedAt}:{' '}
                    {(order.submittedAt || order.createdAt)?.format(
                      'DD/MM/YYYY HH:mm',
                    )}
                  </Typography>
                  <OrderNumber orderId={order.id}/>
                </Stack>
                <Stack>
                  <Typography
                    sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                  >
                    {labels['onDate']}
                  </Typography>
                  <Typography sx={{}}>
                    {order.content.movingDateFormatted}
                  </Typography>
                  <Typography
                    sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                  >
                    {labels['origin']}
                  </Typography>
                  <Typography sx={{}}>
                    {order.content.strDepartAddress}
                  </Typography>
                  <Typography
                    sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                  >
                    {labels['destination']}
                  </Typography>
                  <Typography sx={{}}>
                    {order.content.strArriveAddress}
                  </Typography>
                </Stack>
              </Stack>
              <Box
                sx={{
                  padding: 1,
                  backgroundColor: '#F3EBFF',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ChevronLeftIcon sx={{color: '#6F4DFF'}}/>
              </Box>
            </Stack>
          )
        }),
    )

  const submittedOffersSection = (sectionName: string) =>
    section(
      sectionName,
      labels['submittedOffers1'],
      labels['submittedOffers2'],
      partner.submittedOffersOrders.map((order) => {
        const sortedOffers = _.sortBy(order.offers, 'createdAt').reverse()
        const offer = sortedOffers.filter(
          (offer) => offer.status === 'submitted',
        )[0]
        const formattedAmount = offer.amount
          ? numbro(offer.amount).formatCurrency({
            thousandSeparated: true,
            mantissa: 0,
            currencySymbol: '₪',
          })
          : '-'
        return (
          <Stack gap={1} key={offer.id}>
            <Stack
              onClick={() => onClickOrder(order)}
              gap={1}
              direction={'row'}
              sx={{
                cursor: 'pointer',
                border: '1px solid #F3EBFF',
                borderRadius: 1,
                clipPath: 'border-box',
              }}
              key={order.id}
            >
              <Box sx={{padding: 1, flexGrow: 1}}>
                <Stack
                  direction={'row'}
                  gap={2}
                  alignItems={'center'}
                  sx={{
                    padding: 1,
                    alignSelf: 'center',
                    flexGrow: 1,
                    borderRadius: 1,
                    backgroundColor: '#F3EBFF',
                  }}
                >
                  <OrderNumber orderId={order.id}/>
                  <Stack>
                    <Typography
                      sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                    >
                      {labels['submittedAmount']}
                    </Typography>
                    <Typography sx={{fontSize: 18}}>
                      {formattedAmount}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography
                  sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                >
                  {labels['onDate']}
                </Typography>
                <Typography sx={{}}>
                  {order.content.movingDateFormatted}
                </Typography>
                <Typography
                  sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                >
                  {labels['origin']}
                </Typography>
                <Typography sx={{}}>
                  {order.content.strDepartAddress}
                </Typography>
                <Typography
                  sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
                >
                  {labels['destination']}
                </Typography>
                <Typography sx={{}}>
                  {order.content.strArriveAddress}
                </Typography>
              </Box>

              <Box
                sx={{
                  padding: 1,
                  backgroundColor: '#F3EBFF',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ChevronLeftIcon sx={{color: '#6F4DFF'}}/>
              </Box>
            </Stack>
          </Stack>
        )
      }),
    )

  const declinedOrdersSection = (sectionName: string) => (
    section(sectionName, labels['ordersIRejected1'], null,
      partner.declinedOrders.map((order) => {
        return (
          <Stack
            onClick={() => onClickOrder(order)}
            gap={1}
            direction={'row'}
            sx={{
              cursor: 'pointer',
              border: '1px solid #ececec',
              borderRadius: 1,
              clipPath: 'border-box',
            }}
            key={order.id}
          >
            <Box sx={{padding: 1, flexGrow: 1, position: 'relative'}}>
              <OrderNumber
                orderId={order.id}
                sx={{position: 'absolute', top: 5, right: 0}}
              />
              <Typography
                sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
              >
                {labels['onDate']}
              </Typography>
              <Typography sx={{}}>
                {order.content.movingDateFormatted}
              </Typography>
              <Typography
                sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
              >
                {labels['origin']}
              </Typography>
              <Typography sx={{}}>
                {order.content.strDepartAddress}
              </Typography>
              <Typography
                sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}
              >
                {labels['destination']}
              </Typography>
              <Typography sx={{}}>
                {order.content.strArriveAddress}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: 1,
                backgroundColor: '#ececec',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeftIcon sx={{color: '#b1b1b1'}}/>
            </Box>
          </Stack>
        )
      }))
  );

  // const expiredOffersSection = () => <Stack gap={0} sx={{
  //   borderRadius: 1,
  //   backgroundColor: "#ffffff",
  //   clipPath: 'padding-box',
  // }}>
  //   <Stack direction={"row"} gap={2} sx={{
  //     padding: 2,
  //     alignItems: "center",
  //     backgroundColor: "#ececec",
  //   }}>
  //     <Box sx={{
  //       flexGrow: 1
  //     }}>
  //       <Typography sx={{
  //         fontWeight: 600,
  //         fontSize: 16,
  //       }}>{labels['ordersIMissed']}</Typography>
  //     </Box>
  //   </Stack>
  //   <Stack gap={2} sx={{padding: 2}}>
  //     {
  //       partner.expiredOrders.map(order => {
  //         return <Stack
  //           onClick={() => onClickOrder(order)}
  //           gap={1}
  //           direction={'row'}
  //           sx={{
  //             cursor: 'pointer',
  //             border: "1px solid #ececec",
  //
  //             borderRadius: 1,
  //             clipPath: 'border-box'
  //           }} key={order.id}>
  //           <Box sx={{padding: 1, flexGrow: 1, position: 'relative'}}>
  //             <OrderNumber orderId={order.id} sx={{position: 'absolute', top: 5, right: 0}}/>
  //             <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['onDate']}</Typography>
  //             <Typography sx={{}}>{order.content.entries.dateDay.toSummaryDisplay()}</Typography>
  //             <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['origin']}</Typography>
  //             <Typography sx={{}}>{order.content.entries.departAddress.toSummaryDisplay()}</Typography>
  //             <Typography sx={{fontSize: 12, fontWeight: 600, opacity: 0.5}}>{labels['destination']}</Typography>
  //             <Typography sx={{}}>{order.content.entries.arriveAddress.toSummaryDisplay()}</Typography>
  //           </Box>
  //           <Box sx={{
  //             padding: 1,
  //             backgroundColor: "#ececec",
  //             display: 'flex',
  //             alignItems: 'center',
  //           }}>
  //             <ChevronLeftIcon sx={{color: "#b1b1b1"}}/>
  //           </Box>
  //         </Stack>
  //       })
  //     }
  //   </Stack>
  // </Stack>;

  const noOrders = () => (
    <Stack
      sx={{
        padding: 2,
        borderRadius: 1,
        backgroundColor: 'white',
      }}
      gap={2}
    >
      <Stack>
        <Typography>{labels['noNewOrders']}</Typography>
      </Stack>
    </Stack>
  )

  return (
    <Stack
      sx={{
        margin: 2,
      }}
      gap={2}
    >
      <PartnerMainHeader partner={partner}/>
      <Stack>
      {inviteOffersSection('section1')}
      {acceptedOffers('section2')}
      {submittedOffersSection('section3')}
      {declinedOrdersSection('section4')}
      {/*{expiredOffersSection()}*/}
      </Stack>
    </Stack>
  )
}

function PartnerMainHeader({
                             partner,
                           }: {
  partner: ReturnType<typeof usePartner>
}) {
  return (
    <Stack
      gap={2}
      sx={{
        padding: 2,
        backgroundColor: '#F3EBFF',
        borderRadius: 1,
        clipPath: 'padding-box',
      }}
    >
      <Stack
        direction={'row'}
        gap={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <ProfilePhoto partner={partner}/>
        <Stack>
          <Typography variant={'h5'}>{partner.businessName}</Typography>
        </Stack>
        {/*<Stack sx={{flexGrow: 1, alignItems: 'flex-end'}}>*/}
        {/*  /!* @ts-expect-error *!/*/}
        {/*  <Link to={'/partner/profile'}><Button>{labels.myProfile}</Button></Link>*/}
        {/*</Stack>*/}
      </Stack>
    </Stack>
  )
}
