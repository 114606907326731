import dayjs from 'dayjs';
import {ICCustomer} from '../client-types';
import {ClientCustomerOwnOrder} from "./client-order";

export class ClientCustomer {
  readonly id: number;
  readonly isAnonymous: boolean;
  readonly phone: string | null;
  readonly formattedPhone: string | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly orders: ClientCustomerOwnOrder[];
  readonly cardSuffix: string;
  readonly createdAt: dayjs.Dayjs;
  readonly updatedAt: dayjs.Dayjs;
  readonly orderUploadToken: string;
  readonly rawCustomer: ICCustomer;
  
  constructor(customer: ICCustomer) {
    this.rawCustomer = customer;
    this.orders = customer.orders.map(order => new ClientCustomerOwnOrder(order, this));
    this.id = customer.id;
    this.isAnonymous = customer.isAnonymous;
    this.phone = customer.phone;
    this.formattedPhone = customer.formattedPhone;
    this.firstName = customer.firstName;
    this.lastName = customer.lastName;
    this.email = customer.email;
    this.cardSuffix = customer.cardSuffix;
    this.createdAt = dayjs(customer.createdAt);
    this.updatedAt = dayjs(customer.updatedAt);
    this.orderUploadToken = customer.orderUploadToken;
  }

  get isRegistered() {
    return !!(this.firstName && this.lastName);
  }
  
  findOrder(orderId: number) {
    return this.orders.find(order => order.id === orderId);
  }
  
  findOrderForContractId(contractId: number) {
    return this.orders.find(order => order.contracts.find(contract => contract.id === contractId));
  }
  
  private get sortedOrders() {
    return this.orders.sort((a, b) => a.createdAt.isSame(b.createdAt) ? 0 : a.createdAt.isBefore(b.createdAt) ? 1 : -1);
  }
  get hasDraft() {
    return !!this.latestDraft;
  }
  get latestOrder() {
    return this.sortedOrders[0];
  }
  get latestNonDeletedNonCancelledOrder() {
    return this.sortedOrders.find(order => order.status !== 'deleted' && order.status !== 'cancelled');
  }
  get latestDraft() {
    return this.sortedOrders.find(order => order.status === 'draft');
  }
  get latestSubmittedOrder() {
    return this.sortedOrders.find(order => order.status === 'submitted');
  }
  get latestOrderOpen() {
    return this.sortedOrders.find(order => order.status === 'open');
  }
  get latestOrderOfferAccepted() {
    return this.sortedOrders.find(order => order.status === 'closed');
  }
}
