import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import {FormAddress} from './client-types';
import {sanitizeMessageTextSpaces} from './util';
import {
  ArriveAddress,
  ArriveCrane,
  ArriveCraneComments,
  ArriveFloor,
  ArriveHouseFloorsNum,
  ArriveHouseStructure,
  ArriveLift,
  ArriveParking,
  ArriveParkingComments,
  BagsCount,
  BoxesCount,
  BuilderBagsCount,
  ConsentPhoneCalls,
  ConsentTextMessages,
  // CouchCleaningMedia,
  // CouchCleaningService,
  DepartAddress,
  DepartBalconyCount,
  DepartCrane,
  DepartCraneComments,
  DepartExternalSpace,
  DepartFloor,
  DepartHouseFloorsNum,
  DepartHouseStructure,
  DepartLift,
  DepartParking,
  DepartParkingComments,
  DepartRooms,
  FirstNameField,
  // HandymanComments,
  // HandymanMedia,
  // HandymanService,
  LastNameField,
  // LocksmithService,
  OrderAdditionalOffers,
  OrderComments,
  OrderDateDay,
  OrderDateDayHours,
  OrderPhoneField,
  OrderServiceGroup,
  // PackingMaterials,
  PackingService,
  SacksCount,
  SmallMovingBoxesCount,
  SuitcasesCount,
  UnpackingBagsCount,
  UnpackingBathroomCount,
  UnpackingBoxesCount,
  UnpackingBuilderBagsCount,
  UnpackingClosetsCount,
  UnpackingDateDay,
  UnpackingHasBags,
  UnpackingHasBoxes,
  UnpackingHasBuilderBags,
  UnpackingHasSacks,
  UnpackingHasSuitcases,
  UnpackingKitchenCount,
  UnpackingRooms,
  UnpackingSacksCount,
  UnpackingService,
  UnpackingSuitcasesCount
} from './order-entries';

export type OrderContentEntryType = 'address' | 'text' | 'checkbox' | 'select' | 'multiselect' | 'number' | 'media';

export type OrderContentEntryValue = string | boolean | number | string[] | FormAddress;

export interface OrderMedia {
  bucketPath: string;
  url?: string;
  type: 'image' | 'video';
  // entry: keyof OrderFormEntries;
}

export interface OrderFormEntries {
  orderServiceGroup: OrderServiceGroup;
  phone: OrderPhoneField;
  firstName: FirstNameField;
  lastName: LastNameField;
  departAddress: DepartAddress;
  departFloor: DepartFloor;
  departRooms: DepartRooms;
  departParking: DepartParking;
  departParkingComments: DepartParkingComments;
  departLift: DepartLift;
  departCrane: DepartCrane;
  departCraneComments: DepartCraneComments;
  departExternalSpace: DepartExternalSpace;
  departBalconyCount: DepartBalconyCount;
  departHouseStructure: DepartHouseStructure;
  departHouseFloorsNum: DepartHouseFloorsNum;
  arriveAddress: ArriveAddress;
  arriveFloor: ArriveFloor;
  arriveParking: ArriveParking;
  arriveParkingComments: ArriveParkingComments;
  arriveLift: ArriveLift;
  arriveCrane: ArriveCrane;
  arriveCraneComments: ArriveCraneComments;
  arriveHouseStructure: ArriveHouseStructure;
  arriveHouseFloorsNum: ArriveHouseFloorsNum;
  dateDay: OrderDateDay;
  dateDayHours: OrderDateDayHours;
  bagsCount: BagsCount;
  suitcasesCount: SuitcasesCount;
  sacksCount: SacksCount;
  builderBagsCount: BuilderBagsCount;
  boxesCount: BoxesCount;
  smallMovingBoxesCount: SmallMovingBoxesCount;
  orderComments: OrderComments;
  additionalOffers: OrderAdditionalOffers;
  // packingMaterials: PackingMaterials;
  packingService: PackingService;
  unpackingService: UnpackingService;
  unpackingRooms: UnpackingRooms;
  unpackingDateDay: UnpackingDateDay;
  unpackingClosetsCount: UnpackingClosetsCount;
  unpackingKitchenCount: UnpackingKitchenCount;
  unpackingBathroomCount: UnpackingBathroomCount;
  unpackingBoxesCount: UnpackingBoxesCount;
  unpackingBagsCount: UnpackingBagsCount;
  unpackingSuitcasesCount: UnpackingSuitcasesCount;
  unpackingBuilderBagsCount: UnpackingBuilderBagsCount;
  unpackingSacksCount: UnpackingSacksCount;
  unpackingHasBoxes: UnpackingHasBoxes;
  unpackingHasBags: UnpackingHasBags;
  unpackingHasSuitcases: UnpackingHasSuitcases;
  unpackingHasSacks: UnpackingHasSacks;
  unpackingHasBuilderBags: UnpackingHasBuilderBags;
  // handymanService: HandymanService;
  // handymanComments: HandymanComments;
  // handymanMedia: HandymanMedia;
  // locksmithService: LocksmithService;
  // couchCleaningService: CouchCleaningService;
  // couchCleaningMedia: CouchCleaningMedia;
  consentTextMessages: ConsentTextMessages;
  consentPhoneCalls: ConsentPhoneCalls;
}

export abstract class OrderContentEntry<T = any> {
  name: string;
  type: OrderContentEntryType;
  label: string;
  rawValue: T;
  defaultValue: T;
  formLabel: string;
  formSubtitle?: string;
  formPlaceholder?: string;
  formErrorMessageRequired?: string;
  formErrorMessageMaxLen?: string;
  formErrorMessageMin?: string;
  formErrorMessageMax?: string;
  formErrorMessageFutureDate?: string;
  options?: Array<{ value: any, formLabel: string, label: string, messageDisplay?: string; }>;
  maxLen?: number;
  min?: number;
  max?: number;
  maxFutureDays?: number;

  protected constructor(
    {
      name,
      type,
      value,
      defaultValue,
      formLabel,
      label,
      formPlaceholder,
      formSubtitle,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
      formErrorMessageFutureDate,
      maxLen,
      min,
      max,
      maxFutureDays,
    }: {
      name: string,
      type: OrderContentEntryType,
      value: T,
      defaultValue: T,
      formLabel: string,
      label: string,
      formPlaceholder?: string,
      formSubtitle?: string,
      formErrorMessageRequired: string,
      formErrorMessageMaxLen?: string,
      formErrorMessageMin?: string,
      formErrorMessageMax?: string,
      formErrorMessageFutureDate?: string,
      maxLen?: number,
      min?: number,
      max?: number,
      maxFutureDays?: number,
    }
  ) {
    this.type = type;
    this.name = name;
    this.rawValue = value;
    this.defaultValue = defaultValue;
    this.formLabel = formLabel;
    this.label = label;
    this.formPlaceholder = formPlaceholder;
    this.formSubtitle = formSubtitle;
    this.formErrorMessageRequired = formErrorMessageRequired;
    this.formErrorMessageMaxLen = formErrorMessageMaxLen;
    this.formErrorMessageMin = formErrorMessageMin;
    this.formErrorMessageMax = formErrorMessageMax;
    this.formErrorMessageFutureDate = formErrorMessageFutureDate;
    this.maxLen = maxLen || 1000;
    this.min = min;
    this.max = max;
    this.maxFutureDays = maxFutureDays;
  }

  get value() {
    return this.sanitize();
  }

  abstract isValid(): boolean;

  abstract sanitize(): T;

  abstract toSummaryDisplay(siblingEntries: OrderFormEntries): string;

  toMessageValueDisplay(siblingEntries: OrderFormEntries): string {
    return sanitizeMessageTextSpaces(this.toSummaryDisplay(siblingEntries) || '-');
  }

  toRequiredMessageDisplay(siblingEntries: OrderFormEntries): string {
    const text = this.toMessageValueDisplay(siblingEntries);
    if (text === '-') {
      return '⚠️ יש להשלים שדה זה';
    } else {
      return text;
    }
  }

  isVisible(siblingEntries: OrderFormEntries) {
    return true;
  }

  validateRequiredForm() {
    if (!this.isValid()) {
      return this.formErrorMessageRequired;
    }
    return true;
  }

  validForSubmission(siblingEntries: OrderFormEntries) {
    return this.isValid();
  }
}

export abstract class OrderEntryMedia extends OrderContentEntry<OrderMedia[]> {
  protected constructor({
                          name,
                          value,
                          formLabel,
                          label,
                          formErrorMessageRequired,
                          minCount,
                          maxCount,
                          mediaTypes,
                        }: {
    name: string,
    value: OrderMedia[],
    formLabel: string,
    label: string,
    formErrorMessageRequired?: string,
    minCount?: number,
    maxCount?: number,
    mediaTypes?: ('image' | 'video')[],
  }) {
    super({
      type: 'media',
      name,
      value,
      defaultValue: [],
      formLabel,
      label,
      formErrorMessageRequired,
    });
    this.minCount = minCount || 0;
    this.maxCount = maxCount;
    this.mediaTypes = mediaTypes || ['image', 'video'];
  }

  minCount: number;
  maxCount: number;
  mediaTypes: ('image' | 'video')[];

  sanitize() {
    return this.rawValue.slice(0, this.maxCount).map(m => ({
      bucketPath: m.bucketPath,
      type: m.type,
      url: m.url,
    }));
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    return this.value.length > 0 ? `${this.value} תמונות` : 'אין תמונות';
  }

  isValid() {
    const valid = this.value.length >= this.minCount && this.value.length <= this.maxCount;
    console.log('valid', valid);
    return valid;
  }
}

export abstract class OrderEntryAddress extends OrderContentEntry<FormAddress> {
  protected constructor({
                          name,
                          value,
                          formLabel,
                          label,
                          formErrorMessageRequired,
                          formErrorMessageMaxLen,
                          formErrorMessageMin,
                          formErrorMessageMax,
                        }: {
    name: string,
    value: FormAddress | null;
    formLabel: string;
    label: string;
    formErrorMessageRequired?: string;
    formErrorMessageMaxLen?: string;
    formErrorMessageMin?: string;
    formErrorMessageMax?: string;
  }) {
    super({
      type: 'address',
      name,
      value,
      defaultValue: null,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
    });
  }

  sanitize() {
    return {
      description: String((this.rawValue?.description || '')).trim().slice(0, 1000),
      mainText: String((this.rawValue?.mainText || '')).trim().slice(0, 1000),
      secondaryText: String((this.rawValue?.secondaryText || '')).trim().slice(0, 1000),
    } satisfies FormAddress;
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    return this.value?.description;
  }

  isValid() {
    const hasDescription = typeof this.value?.description === 'string' && this.value?.description.length > 0;
    const hasMainText = typeof this.value?.mainText === 'string' && this.value?.mainText.length > 0;
    const hasSecondaryText = typeof this.value?.secondaryText === 'string' && this.value?.secondaryText.length > 0;
    return hasDescription && hasMainText && hasSecondaryText;
  }
}

export abstract class OrderEntryText extends OrderContentEntry<string> {
  protected constructor({
                          name,
                          value,
                          defaultValue,
                          formLabel,
                          label,
    formSubtitle,
                          formPlaceholder,
                          formErrorMessageRequired,
                          formErrorMessageMaxLen,
                          formErrorMessageMin,
                          formErrorMessageMax,
                          maxLen,
                        }: {
    name: string,
    value: string,
    defaultValue: string,
    formLabel: string,
    label: string,
    formSubtitle?: string,
    formPlaceholder?: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    maxLen?: number,
  }) {
    super({
      type: 'text',
      name,
      value,
      defaultValue,
      formLabel,
      label,
      formSubtitle,
      formPlaceholder,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
      maxLen,
    });
  }

  sanitize() {
    return String(this.rawValue || '').trim().slice(0, this.maxLen);
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    return this.value;
  }

  isValid() {
    if (typeof this.value !== 'string') {
      return false;
    }
    if (this.formErrorMessageRequired) {
      if (this.value.length === 0) {
        return false;
      }
      if (this.maxLen && this.value.length > this.maxLen) {
        return false;
      }
    }
    return true;
  }
}

export abstract class OrderEntryNumber extends OrderContentEntry<number> {
  constructor({
                name,
                value,
                defaultValue,
                formLabel,
                formSubtitle,
                label,
                formErrorMessageRequired,
                formErrorMessageMaxLen,
                formErrorMessageMin,
                formErrorMessageMax,
                min,
                max,
              }: {
    name: string,
    value: number,
    defaultValue: number,
    formLabel: string,
    formSubtitle?: string,
    label: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    min?: number,
    max?: number,
  }) {
    super({
      type: 'number',
      name,
      value: parseInt(String(value)),
      defaultValue: parseInt(String(defaultValue)),
      formLabel,
      formSubtitle,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
      min,
      max,
    });
  }

  sanitize() {
    const parsed = parseInt(String(this.rawValue));
    if (Number.isNaN(parsed)) {
      return this.defaultValue;
    }
    return parsed;
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    return this.value.toString();
  }

  isValid() {
    if (typeof this.value !== 'number') {
      return false;
    }
    if (typeof this.min === 'number' && this.value < this.min) {
      return false;
    }
    if (typeof this.max === 'number' && this.value > this.max) {
      return false;
    }
    return true;
  }
}

export abstract class OrderEntrySelect extends OrderContentEntry<string> {
  protected constructor({
                          name,
                          value,
                          defaultValue,
                          formLabel,
                          label,
                          formSubtitle,
                          formErrorMessageRequired,
                          formErrorMessageMaxLen,
                          formErrorMessageMin,
                          formErrorMessageMax,
                          options,
                        }: {
    name: string,
    value: string,
    defaultValue: string,
    formLabel: string,
    label: string,
    formSubtitle?: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    options: Array<{ value: any, formLabel: string, label: string, messageDisplay?: string }>,
  }) {
    super({
      type: 'select',
      name,
      value,
      defaultValue,
      formLabel,
      label,
      formSubtitle,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
    });
    this.options = options;
  }

  sanitize() {
    const selectedOption = this.options.find(option => option.value === this.rawValue);
    return selectedOption?.value || this.defaultValue;
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    const selectedOption = this.options.find(option => option.value === this.value);
    return selectedOption?.formLabel || '-';
  }

  isValid() {
    if (!this.options.map(opt => opt.value).includes(this.value)) {
      return false;
    }
    return true;
  }
}

export abstract class OrderEntryMultiselect extends OrderContentEntry<string[]> {
  protected constructor({
                          name,
                          value,
                          defaultValue,
                          formLabel,
                          label,
                          formErrorMessageRequired,
                          formErrorMessageMaxLen,
                          formErrorMessageMin,
                          formErrorMessageMax,
                          options,
                        }: {
    name: string,
    value: string[],
    defaultValue: string[],
    formLabel: string,
    label: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    options: Array<{ value: any, formLabel: string, label: string, messageDisplay?: string }>,
  }) {
    super({
      type: 'multiselect',
      name,
      value,
      defaultValue,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
    });
    this.options = options;
  }

  sanitize() {
    const selectedOptions = this.options.filter(option => this.rawValue.includes(option.value));
    return selectedOptions.map(option => option.value);
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    const selectedOptions = this.options.filter(option => this.value.includes(option.value));
    return selectedOptions.map(option => option.label).join(', ');
  }

  isValid() {
    return this.value.length > 0 && _.difference(this.value, this.options.map(opt => opt.value)).length === 0;
  }
}

export abstract class OrderEntryDay extends OrderContentEntry<string> {
  protected constructor({
                          name,
                          value,
                          defaultValue,
                          formLabel,
                          label,
                          formErrorMessageRequired,
                          formErrorMessageMaxLen,
                          formErrorMessageMin,
                          formErrorMessageMax,
                          formErrorMessageFutureDate,
                          maxFutureDays,
                        }: {
    name: string,
    value: string,
    defaultValue: string | null,
    formLabel: string,
    label: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    formErrorMessageFutureDate?: string,
    maxFutureDays?: number,
  }) {
    super({
      type: 'text',
      name,
      value,
      defaultValue,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
      formErrorMessageFutureDate,
      maxFutureDays,
    });
  }

  sanitize() {
    const d = dayjs(this.rawValue, 'YYYY-MM-DD');
    if (d.isValid()) {
      return d.format('YYYY-MM-DD');
    } else {
      return null;
    }
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    const d = dayjs(this.value, 'YYYY-MM-DD');
    if (d.isValid()) {
      return d.format('DD/MM/YYYY');
    } else {
      return null;
    }
  }

  isValid() {
    const date = dayjs(this.value, 'YYYY-MM-DD');
    if (!date.isValid()) {
      return false;
    }
    if (this.maxFutureDays) {
      const maxDate = dayjs().add(this.maxFutureDays, 'day');
      if (date.isAfter(maxDate)) {
        return false;
      }
    }
    return true;
  }
}
