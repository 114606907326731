import dayjs from 'dayjs';
import {
  ICOfferStatus,
  ICPartner,
  ICPartnerPublicProfile,
  PartnerOnboardingStatus,
  PartnerSocials
} from '../client-types';
import {AggregatedRating} from '../rating';
import {ClientOrderForPartner} from './client-order';
import {ServiceName} from '../partner-service';

export class ClientPartnerPublicProfile {
  id: number;
  businessName: string;
  serviceNames: ServiceName[];
  phone: string;
  profilePhotos: string[];
  socials: PartnerSocials;
  acceptsCard: boolean;
  acceptsBit: boolean;

  constructor(partner: ICPartnerPublicProfile) {
    this.id = partner.id;
    this.businessName = partner.businessName;
    this.serviceNames = partner.serviceNames;
    this.phone = partner.phone;
    this.profilePhotos = partner.profilePhotos;
    this.socials = partner.socials;
    this.acceptsCard = partner.acceptsCard;
    this.acceptsBit = partner.acceptsBit;
  }

  get hasSocials() {
    return !!this.socials?.facebook?.url || !!this.socials?.instagram?.url || !!this.socials?.twitter?.url || !!this.socials?.tiktok?.url || !!this.socials?.website?.url;
  }

  get avgRating() {
    const tarimliRating = this.socials?.tarimliRating || {};
    const rating = [
      parseFloat(String(tarimliRating?.quality)),
      parseFloat(String(tarimliRating?.service)),
      parseFloat(String(tarimliRating?.timing)),
    ].filter(x => !isNaN(x) && typeof x === 'number' && x > 0);
    if (rating.length === 0) {
      return null;
    }
    const sum = rating.reduce((acc, x) => acc + x, 0);
    const avg = sum / rating.length;
    return Math.round(avg * 100) / 100;
  }

}

export class ClientPartner {
  id: number;
  phone: string;
  businessName: string;
  serviceNames: ServiceName[];
  profilePhotos: string[];
  socials: PartnerSocials;
  rating: AggregatedRating;
  plusVat: boolean;
  acceptsCard: boolean;
  acceptsBit: boolean;
  orders: ClientOrderForPartner[];
  onboardingStatus: PartnerOnboardingStatus;
  publicProfile: ClientPartnerPublicProfile;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(partner: ICPartner) {
    this.id = partner.id;
    this.phone = partner.phone;
    this.businessName = partner.businessName;
    this.serviceNames = partner.serviceNames;
    this.profilePhotos = partner.profilePhotos;
    this.plusVat = partner.plusVat;
    this.acceptsCard = partner.acceptsCard;
    this.acceptsBit = partner.acceptsBit;
    this.socials = partner.socials;
    this.orders = partner.orders.map(order => new ClientOrderForPartner(order));
    this.onboardingStatus = partner.onboardingStatus;
    this.publicProfile = new ClientPartnerPublicProfile({
      id: partner.id,
      businessName: partner.businessName,
      serviceNames: partner.serviceNames,
      phone: partner.phone,
      profilePhotos: partner.profilePhotos,
      socials: partner.socials,
      acceptsCard: partner.acceptsCard,
      acceptsBit: partner.acceptsBit,
    });
    this.createdAt = dayjs(partner.createdAt);
    this.updatedAt = dayjs(partner.updatedAt);
  }

  hasService(serviceName: ServiceName) {
    return this.serviceNames.includes(serviceName);
  }

  hasEveryService(serviceNames: ServiceName[]) {
    return serviceNames.every(s => this.hasService(s));
  }

  hasSomeService(serviceNames: ServiceName[]) {
    return serviceNames.some(s => this.hasService(s));
  }

  get canSubmitOffers() {
    // return this.onboardingStatus?.registrationCompleted && this.onboardingStatus?.termsConfirmed;
    return this.onboardingStatus?.registrationCompleted;
  }

  get onboardingRegistrationCompleted() {
    return !!this.onboardingStatus?.registrationCompleted;
  }

  findOrder(orderId: number) {
    const order = this.orders.find(or => or.id === orderId);
    return order || null;
  }

  get offers() {
    return this.orders.map(order => order.offers).flat();
  }

  get hasOrders() {
    return this.orders.length > 0;
  }

  get hasOrdersForOffer() {
    return this.ordersForOffer.length > 0;
  }

  get ordersForOffer() {
    return this.orders.filter(order => order.isOpenForOffers);
  }

  get submittedOffersOrders() {
    return this.getOrdersWithOfferStatus('submitted');
  }

  get hasSubmittedOffers() {
    return this.submittedOffersOrders.length > 0;
  }

  getOrdersWithOfferStatus(status: ICOfferStatus) {
    return this.orders.filter(order => order.offers.some(o => o.status === status));
  }

  get acceptedOffersOrders() {
    return this.getOrdersWithOfferStatus('accepted');
  }

  get declinedOrders() {
    return this.orders.filter(order => order.isDeclined);
  }

  // get expiredOrders() {
  //   return this.orders.filter(order => order.isExpired);
  // }
  //
  // get hasDeclinedOffers() {
  //   return this.declinedOrders.length > 0;
  // }
  //
  // get hasExpiredOrders() {
  //   return this.expiredOrders.length > 0;
  // }

}
