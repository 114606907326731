import '../util/dayjs';
import {
  ArriveAddress,
  ArriveCrane,
  ArriveCraneComments,
  ArriveFloor,
  ArriveHouseFloorsNum,
  ArriveHouseStructure,
  ArriveLift,
  ArriveParking,
  ArriveParkingComments,
  BagsCount,
  BoxesCount,
  BuilderBagsCount,
  ConsentPhoneCalls,
  ConsentTextMessages,
  // CouchCleaningMedia,
  // CouchCleaningService,
  DepartAddress,
  DepartBalconyCount,
  DepartCrane,
  DepartCraneComments,
  DepartExternalSpace,
  DepartFloor,
  DepartHouseFloorsNum,
  DepartHouseStructure,
  DepartLift,
  DepartParking,
  DepartParkingComments,
  DepartRooms,
  FirstNameField,
  // HandymanComments,
  // HandymanMedia,
  // HandymanService,
  LastNameField,
  // LocksmithService,
  OrderAdditionalOffers,
  OrderComments,
  OrderContentNames,
  OrderContentValues,
  OrderDateDay,
  OrderDateDayHours,
  OrderPhoneField,
  OrderServiceGroup,
  // PackingMaterials,
  PackingService,
  SacksCount,
  SmallMovingBoxesCount,
  SuitcasesCount,
  UnpackingBagsCount,
  UnpackingBathroomCount,
  UnpackingBoxesCount,
  UnpackingBuilderBagsCount,
  UnpackingClosetsCount,
  UnpackingDateDay,
  UnpackingHasBags,
  UnpackingHasBoxes,
  UnpackingHasBuilderBags,
  UnpackingHasSacks,
  UnpackingHasSuitcases,
  UnpackingKitchenCount,
  UnpackingRooms,
  UnpackingSacksCount,
  UnpackingService,
  UnpackingSuitcasesCount,
} from './order-entries';
import {OrderContentEntry, OrderFormEntries} from './order-entries-types';
import {OrderItem, OrderItemData, OrderItemProps} from './order-items-types';
import dayjs from 'dayjs';

import {sharedConfig} from '../shared/shared-config';
import {ServiceGroupName, ServiceGroupNames, ServiceName} from './partner-service';
import {categories} from './items-data';
import {ICCustomerOwnOrder} from './client-types';

const labels = {
  items: 'פריטים',
  itemsCount: 'מספר פריטים',
  orderId: '#️⃣ מספר הזמנה:',
  missing: 'לא צוין',
  noCategory: 'אחר',
  plusAssembly: '+ הרכבה',
  plusDisassembly: '+ פירוק',
  plusThrowAway: '+ פינוי לפח',
  craneNeeded: 'נדרש מנוף',
};

export class OrderContent {
  orderId: number;
  entries: OrderFormEntries;
  items: OrderItem[];
  version: string;
  pdfFile: string | null;
  pdfHash: string | null;
  executionDate: dayjs.Dayjs | null;
  submissionValidator: (entries: Partial<Record<OrderContentNames, OrderContentEntry>>) => Partial<Record<OrderContentNames, boolean>>;

  constructor(orderId: number, json: Record<string, any> = {}, items: OrderItemData[] = []) {
    this.orderId = orderId;
    this.version = json._version || '1';
    this.pdfFile = json.pdfFile || null;
    this.pdfHash = json.pdfHash || null;
    this.items = items.map(item => new OrderItem(item));
    switch (this.version) {
      case '1': {
        this.entries = orderJsonToEntries(json);
        this.submissionValidator = entriesSubmissionValidator;
      }
        break;
      default: {
        throw new Error(`Unknown order version: ${this.version}`);
      }
    }
    this.executionDate = this.entries.dateDay ? dayjs(this.entries.dateDay.value) : null;
  }
  
  static fromICCustomerOwnOrder(order: ICCustomerOwnOrder): OrderContent {
    return new OrderContent(order.id, order.content, order.items);
  }
  //
  // getMediaForEntry(entry: keyof OrderFormEntries): OrderMedia[] {
  //   return this.media.filter(m => m.entry === entry);
  // }

  get isSmallMoving(): boolean {
    return this.entries.orderServiceGroup.value === 'smallMoving';
  }

  get isLargeMoving(): boolean {
    return this.entries.orderServiceGroup.value === 'moving';
  }

  get resolvedServices(): ServiceName[] {
    const names: ServiceName[] = [];
    if (this.isSmallMoving) {
      names.push('smallMoving');
    } else {
      names.push('moving');
      // if (this.entries.packingMaterials.value === 'yes') {
      //   names.push('packingMaterials');
      // }
      if (this.entries.packingService.value === 'yes') {
        names.push('packing');
      }
      if (this.entries.unpackingService.value === 'yes') {
        names.push('unpacking');
      }
    }
    if (this.entries.departCrane.value !== 'no' || this.entries.arriveCrane.value !== 'no') {
      names.push('crane');
    }
    // if (this.entries.handymanService.value === 'yes') {
    //   names.push('handyman');
    // }
    // if (this.entries.locksmithService.value === 'yes') {
    //   names.push('locksmith');
    // }
    return names;
  }

  hasResolvedService(service: ServiceName): boolean {
    return this.resolvedServices.includes(service);
  }

  hasEveryResolvedService(services: ServiceName[]): boolean {
    return services.every(s => this.hasResolvedService(s));
  }

  hasSomeResolvedService(services: ServiceName[]): boolean {
    return services.some(s => this.hasResolvedService(s));
  }

  get resolvedServiceGroups(): ServiceGroupName[] {
    return ServiceGroupNames.filter(groupName => this.hasResolvedService(groupName));
  }

  hasResolvedServiceGroup(group: ServiceGroupName): boolean {
    return this.resolvedServiceGroups.includes(group);
  }

  get submissionValidationState(): Partial<Record<OrderContentNames, boolean>> {
    const result = this.submissionValidator(this.entries);
    console.log('submissionValidationState', result);
    return result;
  }

  getInvalidFieldsForSubmission(except: OrderContentNames[]): OrderContentNames[] {
    const invalid = Object.entries(this.submissionValidationState)
      .filter(([_, isValid]) => !isValid)
      .map(([key, _]) => key as OrderContentNames);
    return invalid.filter(key => !except.includes(key));
  }

  get validForSubmission(): boolean {
    const validated = this.submissionValidationState;
    return Object.values(validated).every(v => v);
  }

  get entriesInvalidForSubmission(): Partial<Record<OrderContentNames, OrderContentEntry>> {
    const validated = this.submissionValidator(this.entries);
    return Object.entries(validated)
      .filter(([_, isValid]) => !isValid)
      .reduce((acc, [key, _]) => {
        acc[key as OrderContentNames] = this.entries[key as OrderContentNames];
        return acc;
      }, {} as Partial<Record<OrderContentNames, OrderContentEntry>>);
  }

  get entriesValidForSubmission(): Partial<Record<OrderContentNames, OrderContentEntry>> {
    const validated = this.submissionValidator(this.entries);
    return Object.entries(validated)
      .filter(([_, isValid]) => isValid)
      .reduce((acc, [key, _]) => {
        acc[key as OrderContentNames] = this.entries[key as OrderContentNames];
        return acc;
      }, {} as Partial<Record<OrderContentNames, OrderContentEntry>>);
  }

  sanitize(): OrderContentValues {
    const sanitizedEntries = {
      _version: this.version,
      pdfFile: this.pdfFile,
      pdfHash: this.pdfHash,
      // media: this.media.map(({url, ...m}) => m),
    };
    for (const [name, entry] of Object.entries(this.entries)) {
      sanitizedEntries[name] = entry.sanitize();
    }
    return sanitizedEntries;
  }

  makeItemTextObject(item: OrderItem) {
    return {
      title: item.title,
      propsText: this.makeItemPropsText(item),
      assemblyText: this.makeItemAssemblyText(item),
      comments: item.comments,
    };
  }

  makeItemPropsText(item: OrderItem): string {
    return (item.itemProps || []).filter(p => !!p).map((p: OrderItemProps) => {
      switch (p.type) {
        case 'checkbox':
          return `\n- ${p.config.label}`;
        case 'radio':
          return `\n${p.config.label}: ${p.value}`;
        case 'select':
          return p.value ? `\n${p.config.label}: ${p.value}` : '';
        case 'counter':
          return `\n${p.config.label}: ${p.value}`;
        default:
          return `\n${p.config.label}: ${p.value}`;
      }
    }).join('');
  }

  makeItemAssemblyText(item: OrderItem): string[] {
    return [
      item.assembly ? labels.plusAssembly : null,
      item.disassembly ? labels.plusDisassembly : null,
      item.throwAway ? labels.plusThrowAway : null,
    ].filter(t => !!t);
  }

  makeItemText(item: OrderItem): string {
    const quantity = `(${item.quantity})`;
    const assemblyDisassemblyThrowAway = this.makeItemAssemblyText(item).join(' ');
    const comments = item.comments ? `\n(${item.comments})` : '';
    const itemProps = this.makeItemPropsText(item);
    return `${item.title} ${quantity}${assemblyDisassemblyThrowAway.trim() !== '' ? `\n${assemblyDisassemblyThrowAway}` : ''}${itemProps}${comments}`;
  }

  makeItemsText() {
    return this.items
      .map(item => this.makeItemText(item))
      .join('\n\n');
  }

  toItemsMessageText(): string {
    const itemsText = this.makeItemsText();
    return `
*${labels.items}*:

${itemsText}`;
  }

  toShortItemsMessageText(): string {
    return `*${labels.itemsCount}: ${this.items.length}*`;
  }

  toDetailsMessageText(): string {
    const messageText = `
*${labels.orderId}* ${this.orderId}
*${this.entries.dateDay.label}*: ${this.movingDateFormatted}

*${this.entries.departAddress.label}*: ${this.entries.departAddress.toSummaryDisplay(this.entries)}
*${this.entries.departFloor.label}*: ${this.entries.departFloor.toSummaryDisplay(this.entries)}
*${this.entries.departRooms.label}*: ${this.entries.departRooms.toSummaryDisplay(this.entries)}
*${this.entries.departParking.label}*: ${this.entries.departParking.toSummaryDisplay(this.entries)}
${['no', 'maybe'].includes(this.entries.departParking.value) ? `*${this.entries.departParkingComments.label}*: ${this.entries.departParkingComments.toSummaryDisplay(this.entries)}` : ''}
*${this.entries.departLift.label}*: ${this.entries.departLift.toSummaryDisplay(this.entries)}
*${this.entries.departCrane.label}*: ${this.entries.departCrane.toSummaryDisplay(this.entries)}
${['yes', 'maybe'].includes(this.entries.departCrane.value) ? `*${this.entries.departCraneComments.label}*: ${this.entries.departCraneComments.toSummaryDisplay(this.entries)}` : ''}
*${this.entries.departExternalSpace.label}*: ${this.entries.departExternalSpace.toSummaryDisplay(this.entries)}${this.entries.departExternalSpace.value.includes('balcony') ? `\n*${this.entries.departBalconyCount.label}*: ${this.entries.departBalconyCount.toSummaryDisplay(this.entries)}` : ''}
${['more-floors'].includes(this.entries.departHouseStructure.value) ? `*${this.entries.departHouseFloorsNum.label}*: ${this.entries.departHouseFloorsNum.toSummaryDisplay(this.entries)}` : `*${this.entries.departHouseStructure.label}*: ${this.entries.departHouseStructure.toSummaryDisplay(this.entries)}`
    }

*${this.entries.arriveAddress.label}*: ${this.strArriveAddress}
*${this.entries.arriveFloor.label}*: ${this.entries.arriveFloor.toSummaryDisplay(this.entries)}
*${this.entries.arriveParking.label}*: ${this.entries.arriveParking.toSummaryDisplay(this.entries)}
${['no', 'maybe'].includes(this.entries.arriveParking.value) ? `*${this.entries.arriveParkingComments.label}*: ${this.entries.arriveParkingComments.toSummaryDisplay(this.entries)}` : ''}
*${this.entries.arriveLift.label}*: ${this.entries.arriveLift.toSummaryDisplay(this.entries)}
*${this.entries.arriveCrane.label}*: ${this.entries.arriveCrane.toSummaryDisplay(this.entries)}
${['yes', 'maybe'].includes(this.entries.arriveCrane.value) ? `*${this.entries.arriveCraneComments.label}*: ${this.entries.arriveCraneComments.toSummaryDisplay(this.entries)}` : ''}
${['more-floors'].includes(this.entries.arriveHouseStructure.value) ? `*${this.entries.arriveHouseFloorsNum.label}*: ${this.entries.arriveHouseFloorsNum.toSummaryDisplay(this.entries)}` : `*${this.entries.arriveHouseStructure.label}*: ${this.entries.arriveHouseStructure.toSummaryDisplay(this.entries)}`}
`;
    return messageText;
  }

  toSimpleMessageDetailsText(): string {
    const craneText = this.entries.departCrane.value !== 'no' || this.entries.arriveCrane.value !== 'no' ? ` // *${labels.craneNeeded}*` : '';
    const messageText = `*${this.entries.departAddress.label}*: ${this.entries.departAddress.toSummaryDisplay(this.entries)} // *${this.entries.arriveAddress.label}*: ${this.strArriveAddress} // *${this.entries.dateDay.label}*: ${this.movingDateFormatted}${craneText}`;
    return messageText;
  }

  toMessageText(maxCharCount: number = sharedConfig.maxWaMessageLength): string {
    const fullMessageText = this.toFullMessageText();
    if (maxCharCount === -1 || fullMessageText.length <= maxCharCount) {
      return fullMessageText;
    } else {
      const shortMessageText = this.toShortMessageText();
      return shortMessageText;
    }
  }

  toShortMessageText(): string {
    const bodyText = `${this.toDetailsMessageText()}`;
    return bodyText;
  }

  toFullMessageText(): string {
    const bodyText = `${this.toDetailsMessageText()}
${this.toItemsMessageText()}`;
    return bodyText;
  }

  toAdminEmailHtml(): string {
    const toEntryRow = (entry1: OrderContentEntry<any>, entry2?: OrderContentEntry<any>) => {
      return `<tr>
<td><b>${entry1.label}</b></td>
<td>${entry1.toSummaryDisplay(this.entries)}</td>
<td><b>${entry2?.label || ''}</b></td>
<td>${entry2?.toSummaryDisplay(this.entries) || ''}</td>
</tr>`;
    };
    const toItemsRow = (item: OrderItem) => {
      return `
<tr>
    <td style="padding: 5px">${this.makeItemText(item).replaceAll('\n', '<br />')}</td>
</tr>
`;
    };
    const html = `
<div style="direction: rtl; line-height: 2em;">
<h2>הזמנה #${this.orderId}</h2>
<h3>לאתריך: ${this.entries.dateDay.toSummaryDisplay(this.entries)} ${this.entries.dateDayHours.toSummaryDisplay(this.entries)}</h3>
<table cellpadding="2" cellspacing="2" border="1" style="border-spacing: 1px; border-collapse: separate; border: 1px solid grey; border-color: grey;">
<tr>
<td style="width: 50%" colspan="2"><h4>פרטי מוצא</h4></td>
<td style="width: 50%" colspan="2"><h4>פרטי יעד</h4></td>
</tr>
${[
      [this.entries.departAddress, this.entries.arriveAddress],
      [this.entries.departFloor, this.entries.arriveFloor],
      [this.entries.departRooms, this.entries.arriveParking],
      [this.entries.departParking, this.entries.arriveParkingComments],
      [this.entries.departParkingComments, this.entries.arriveLift],
      [this.entries.departLift, this.entries.arriveCrane],
      [this.entries.departCrane, this.entries.arriveCraneComments],
      [this.entries.departCraneComments, this.entries.arriveHouseStructure],
      [this.entries.departExternalSpace, this.entries.arriveHouseFloorsNum],
      [this.entries.departBalconyCount],
      [this.entries.departHouseStructure],
      [this.entries.departHouseFloorsNum],
    ]
      .map(([entry1, entry2]) => toEntryRow(entry1, entry2))
      .join('\n')
    }
</table>
<h4>פריטים:</h4>
<table cellpadding="2" cellspacing="2" border="1" style="border-spacing: 1px; border-collapse: separate; border: 1px solid grey; border-color: grey;">
${this.items.map(toItemsRow).join('')}
</table>
<h4>הערות להזמנה:</h4>
<p>
${this.entries.orderComments.toSummaryDisplay(this.entries) || '--'}
</p>
<h4>הצעות נוספות:</h4>
<p>
${this.entries.additionalOffers.toSummaryDisplay(this.entries) || '--'}
</p>
</div>
`;
    return html;
  }

  formValues(): Record<string, any> {
    const values: Record<string, any> = {};
    for (const [name, entry] of Object.entries(this.entries)) {
      values[name] = entry.value || entry.defaultValue;
    }
    console.log('this.entries', this.entries);
    console.log('formValues', values);
    return values;
  }

  get sortedItems() {
    return this.items.sort((a, b) => a.title.localeCompare(b.title));
  }

  get itemsByCategoryName(): Record<string, OrderItem[]> {
    const byCategory: Record<string, OrderItem[]> = Object.entries(categories).reduce((acc, [categoryName, categoryItems]) => {
      acc[categoryName] = categoryItems.flatMap(itemName => this.items.filter(item => item.title === itemName));
      return acc;
    }, {});
    const missingItems = this.items.filter(item => !Object.values(categories).flat().includes(item.title));
    byCategory[labels.noCategory] = missingItems;
    return Object.fromEntries(Object.entries(byCategory).filter(([_, items]) => items.length > 0));
  }

  get strDepartAddress(): string {
    return this.entries.departAddress?.value?.description;
  }

  get strArriveAddress(): string {
    return this.entries.arriveAddress?.value?.description;
  }

  get hasDepartExternalSpace(): boolean {
    return this.entries.departExternalSpace?.value?.length > 0;
  }

  get hasItems(): boolean {
    return this.items?.length > 0;
  }

  get totalPackagesCount(): number {
    const boxesCount = this.entries.boxesCount?.value;
    const bagsCount = this.entries.bagsCount?.value;
    const suitcasesCount = this.entries.suitcasesCount?.value;
    const sacksCount = this.entries.sacksCount?.value;
    return boxesCount + bagsCount + suitcasesCount + sacksCount;
  }

  get movingDateRelativeLabel(): string | null {
    if (!this.entries.dateDay) {
      return null;
    }
    return dayjs().to(this.entries.dateDay.value);
  }

  get movingDateFormatted(): string | null {
    if (!this.entries.dateDay?.value) {
      return null;
    }
    return dayjs(this.entries.dateDay.value).format('DD/MM/YYYY');
  }

  get unpackingDateRelativeLabel(): string | null {
    if (!this.entries.unpackingDateDay) {
      return null;
    }
    return dayjs().to(this.entries.unpackingDateDay.value);
  }

  get unpackingDateFormatted(): string | null {
    if (!this.entries.unpackingDateDay?.value) {
      return null;
    }
    return dayjs(this.entries.unpackingDateDay.value).format('DD/MM/YYYY');
  }

  has(entry: keyof OrderContentValues): boolean {
    return !!this.entries[entry];
  }

  get additionalServicesLabel(): string {
    const labels = [];
    // if (this.entries.packingMaterials.value === 'yes') {
    //   labels.push(this.entries.packingMaterials.label);
    // }
    if (this.entries.packingService.value === 'yes') {
      labels.push(this.entries.packingService.label);
    }
    if (this.entries.unpackingService.value === 'yes') {
      labels.push(this.entries.unpackingService.label);
    }
    if (labels.length === 0) {
      return '-';
    } else {
      return labels.join(', ');
    }
  }
}


const orderJsonToEntries = (json: Record<string, any>): OrderFormEntries => {
  return {
    orderServiceGroup: new OrderServiceGroup(json['orderServiceGroup'] || 'moving'),
    phone: new OrderPhoneField(json['phone'] || ''),
    firstName: new FirstNameField(json['firstName'] || null),
    lastName: new LastNameField(json['lastName'] || null),
    departAddress: new DepartAddress(json['departAddress'] || null),
    departFloor: new DepartFloor(json['departFloor'] || ''),
    departRooms: new DepartRooms(json['departRooms'] || ''),
    departParking: new DepartParking(json['departParking'] || ''),
    departParkingComments: new DepartParkingComments(json['departParkingComments'] || ''),
    departLift: new DepartLift(json['departLift'] || ''),
    departCrane: new DepartCrane(json['departCrane'] || ''),
    departCraneComments: new DepartCraneComments(json['departCraneComments'] || ''),
    departExternalSpace: new DepartExternalSpace(json['departExternalSpace'] || []),
    departBalconyCount: new DepartBalconyCount(json['departBalconyCount'] || ''),
    departHouseStructure: new DepartHouseStructure(json['departHouseStructure'] || ''),
    departHouseFloorsNum: new DepartHouseFloorsNum(json['departHouseFloorsNum'] || ''),
    arriveAddress: new ArriveAddress(json['arriveAddress'] || null),
    arriveFloor: new ArriveFloor(json['arriveFloor'] || ''),
    arriveParking: new ArriveParking(json['arriveParking'] || ''),
    arriveParkingComments: new ArriveParkingComments(json['arriveParkingComments'] || ''),
    arriveLift: new ArriveLift(json['arriveLift'] || ''),
    arriveCrane: new ArriveCrane(json['arriveCrane'] || ''),
    arriveCraneComments: new ArriveCraneComments(json['arriveCraneComments'] || ''),
    arriveHouseStructure: new ArriveHouseStructure(json['arriveHouseStructure'] || ''),
    arriveHouseFloorsNum: new ArriveHouseFloorsNum(json['arriveHouseFloorsNum'] || ''),
    dateDay: new OrderDateDay(json['dateDay'] || ''),
    dateDayHours: new OrderDateDayHours(json['dateDayHours'] || []),
    bagsCount: new BagsCount(json['bagsCount'] || 0),
    suitcasesCount: new SuitcasesCount(json['suitcasesCount'] || 0),
    sacksCount: new SacksCount(json['sacksCount'] || 0),
    builderBagsCount: new BuilderBagsCount(json['builderBagsCount'] || 0),
    boxesCount: new BoxesCount(json['boxesCount'] || 0),
    smallMovingBoxesCount: new SmallMovingBoxesCount(json['smallMovingBoxesCount'] || 0),
    orderComments: new OrderComments(json['orderComments'] || ''),
    additionalOffers: new OrderAdditionalOffers(json['additionalOffers'] || []),
    // packingMaterials: new PackingMaterials(json['packingMaterials'] || ''),
    packingService: new PackingService(json['packingService'] || ''),
    unpackingService: new UnpackingService(json['unpackingService'] || ''),
    unpackingRooms: new UnpackingRooms(json['unpackingRooms'] || []),
    unpackingDateDay: new UnpackingDateDay(json['unpackingDateDay'] || ''),
    unpackingClosetsCount: new UnpackingClosetsCount(json['unpackingClosetsCount'] || ''),
    unpackingKitchenCount: new UnpackingKitchenCount(json['unpackingKitchenCount'] || ''),
    unpackingBathroomCount: new UnpackingBathroomCount(json['unpackingBathroomCount'] || ''),
    unpackingBoxesCount: new UnpackingBoxesCount(json['unpackingBoxesCount'] || 0),
    unpackingBagsCount: new UnpackingBagsCount(json['unpackingBagsCount'] || 0),
    unpackingSuitcasesCount: new UnpackingSuitcasesCount(json['unpackingSuitcasesCount'] || 0),
    unpackingSacksCount: new UnpackingSacksCount(json['unpackingSacksCount'] || 0),
    unpackingBuilderBagsCount: new UnpackingBuilderBagsCount(json['unpackingBuilderBagsCount'] || 0),
    unpackingHasBoxes: new UnpackingHasBoxes(json['unpackingHasBoxes'] || ''),
    unpackingHasBags: new UnpackingHasBags(json['unpackingHasBags'] || ''),
    unpackingHasSuitcases: new UnpackingHasSuitcases(json['unpackingHasSuitcases'] || ''),
    unpackingHasSacks: new UnpackingHasSacks(json['unpackingHasSacks'] || ''),
    unpackingHasBuilderBags: new UnpackingHasBuilderBags(json['unpackingHasBuilderBags'] || ''),
    // handymanService: new HandymanService(json['handymanService'] || ''),
    // handymanComments: new HandymanComments(json['handymanComments'] || ''),
    // handymanMedia: new HandymanMedia(json['handymanMedia'] || []),
    // locksmithService: new LocksmithService(json['locksmithService'] || ''),
    // couchCleaningService: new CouchCleaningService(json['couchCleaningService'] || ''),
    // couchCleaningMedia: new CouchCleaningMedia(json['couchCleaningMedia'] || []),
    consentTextMessages: new ConsentTextMessages(json['consentTextMessages'] || ''),
    consentPhoneCalls: new ConsentPhoneCalls(json['consentPhoneCalls'] || ''),
  };
};

const entriesSubmissionValidator = (entries: OrderFormEntries): Record<keyof OrderFormEntries, boolean> => {
  return {
    orderServiceGroup: entries.orderServiceGroup.validForSubmission(entries),
    phone: entries.phone.validForSubmission(entries),
    firstName: entries.firstName.validForSubmission(entries),
    lastName: entries.lastName.validForSubmission(entries),
    departAddress: entries.departAddress.validForSubmission(entries),
    departFloor: entries.departFloor.validForSubmission(entries),
    departRooms: entries.departRooms.validForSubmission(entries),
    departParking: entries.departParking.validForSubmission(entries),
    departParkingComments: entries.departParkingComments.validForSubmission(entries),
    departLift: entries.departLift.validForSubmission(entries),
    departCrane: entries.departCrane.validForSubmission(entries),
    departCraneComments: entries.departCraneComments.validForSubmission(entries),
    departExternalSpace: entries.departExternalSpace.validForSubmission(entries),
    departBalconyCount: entries.departBalconyCount.validForSubmission(entries),
    departHouseStructure: entries.departHouseStructure.validForSubmission(entries),
    departHouseFloorsNum: entries.departHouseFloorsNum.validForSubmission(entries),
    arriveAddress: entries.arriveAddress.validForSubmission(entries),
    arriveFloor: entries.arriveFloor.validForSubmission(entries),
    arriveParking: entries.arriveParking.validForSubmission(entries),
    arriveParkingComments: entries.arriveParkingComments.validForSubmission(entries),
    arriveLift: entries.arriveLift.validForSubmission(entries),
    arriveCrane: entries.arriveCrane.validForSubmission(entries),
    arriveCraneComments: entries.arriveCraneComments.validForSubmission(entries),
    arriveHouseStructure: entries.arriveHouseStructure.validForSubmission(entries),
    arriveHouseFloorsNum: entries.arriveHouseFloorsNum.validForSubmission(entries),
    dateDay: entries.dateDay.validForSubmission(entries),
    dateDayHours: entries.dateDayHours.validForSubmission(entries),
    bagsCount: entries.bagsCount.validForSubmission(entries),
    suitcasesCount: entries.suitcasesCount.validForSubmission(entries),
    sacksCount: entries.sacksCount.validForSubmission(entries),
    builderBagsCount: entries.builderBagsCount.validForSubmission(entries),
    boxesCount: entries.boxesCount.validForSubmission(entries),
    smallMovingBoxesCount: entries.smallMovingBoxesCount.validForSubmission(entries),
    orderComments: entries.orderComments.validForSubmission(entries),
    additionalOffers: true,
    // packingMaterials: entries.packingMaterials.validForSubmission(entries),
    packingService: entries.packingService.validForSubmission(entries),
    unpackingService: entries.unpackingService.validForSubmission(entries),
    unpackingRooms: entries.unpackingRooms.validForSubmission(entries),
    unpackingDateDay: entries.unpackingDateDay.validForSubmission(entries),
    unpackingClosetsCount: entries.unpackingClosetsCount.validForSubmission(entries),
    unpackingKitchenCount: entries.unpackingKitchenCount.validForSubmission(entries),
    unpackingBathroomCount: entries.unpackingBathroomCount.validForSubmission(entries),
    unpackingBoxesCount: entries.unpackingBoxesCount.validForSubmission(entries),
    unpackingBagsCount: entries.unpackingBagsCount.validForSubmission(entries),
    unpackingSuitcasesCount: entries.unpackingSuitcasesCount.validForSubmission(entries),
    unpackingSacksCount: entries.unpackingSacksCount.validForSubmission(entries),
    unpackingBuilderBagsCount: entries.unpackingBuilderBagsCount.validForSubmission(entries),
    unpackingHasBoxes: entries.unpackingHasBoxes.validForSubmission(entries),
    unpackingHasBags: entries.unpackingHasBags.validForSubmission(entries),
    unpackingHasSuitcases: entries.unpackingHasSuitcases.validForSubmission(entries),
    unpackingHasSacks: entries.unpackingHasSacks.validForSubmission(entries),
    unpackingHasBuilderBags: entries.unpackingHasBuilderBags.validForSubmission(entries),
    // handymanService: entries.handymanService.validForSubmission(entries),
    // handymanComments: entries.handymanComments.validForSubmission(entries),
    // handymanMedia: entries.handymanMedia.validForSubmission(entries),
    // locksmithService: entries.locksmithService.validForSubmission(entries),
    // couchCleaningService: entries.couchCleaningService.validForSubmission(entries),
    // couchCleaningMedia: entries.couchCleaningMedia.validForSubmission(entries),
    consentTextMessages: entries.consentTextMessages.validForSubmission(entries),
    consentPhoneCalls: entries.consentPhoneCalls.validForSubmission(entries),
  };
};
